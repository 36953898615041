import { Action } from '@ngrx/store';
import { BreadCrumbItem } from '../reducers/layout.reducer';

export const SET_BREADCRUMB = '[Breadbrumb] Set';
export const HIDE_MOBILE_MENU = '[Mobile menu] Hide';
export const SHOW_MOBILE_MENU = '[Mobile menu] Show';
export const LAYOUT_UPDATE_SUCCESS = '[Layout] Update Success';

export class SetBreadcrumb implements Action {
    readonly type = SET_BREADCRUMB;
    constructor(public payload: BreadCrumbItem[]) { }
}
/** @deprecated - Use mobileMenuService.hide() in libs */
export class HideMobileMenu implements Action {
    readonly type = HIDE_MOBILE_MENU;
}
/** @deprecated - Use mobileMenuService.show() in libs */
export class ShowMobileMenu implements Action {
    readonly type = SHOW_MOBILE_MENU;
}

export class LayoutUpdateSuccess implements Action {
    readonly type = LAYOUT_UPDATE_SUCCESS;
}

export type ActionTypes
    = SetBreadcrumb
    | HideMobileMenu
    | LayoutUpdateSuccess
    | ShowMobileMenu;
